import React from 'react'

export default function Contact() {
  return (
    <div><section className="filmoja-contact-page section_70">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="contact-box-left">
       
            <div className="contact-info">
              <div className="row">
                <div className="col-lg-6">
                  <div className="single-contact-info">
                    <p>Head Office address</p>
                    <h4 >
                    Vidyanchal School,Pune

                      <br />
                   Maharashtra,India
                    </h4>
                  </div>
                  <div className="single-contact-info">
                    <p>get connected with</p>
                    <ul  style={{marginLeft:'4rem'}}>
                    <li>
                                    <a href="https://www.facebook.com/pushkarrangmanch/">
                                        <i className="fab fa-facebook-square" />
                                    </a>
                                </li>
                               
                                <li>
                                    <a href="https://www.instagram.com/pushkarrangmanch/?hl=en">
                                        <i className="fa-brands fa-instagram"/>
                                    </a>
                                </li>
                    
                     
                     
                    </ul>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="single-contact-info">
                    <p>feel like talking</p>
                    <h4>+91-9823900564</h4>
                  </div>
                  <div className="single-contact-info">
                    <p>Contact via email</p>
                    <h4 style={{marginLeft:'-5rem'}}>pushkarrangmanch@gmail.com</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="contact-box-right margin-top">
            <h3>Feel Free To Reach Out To Us Anytime</h3>
            <form>
              <p>
                <input
                  type="text"
                  id="f_name"
                  name="name"
                  placeholder="Your Name"
                  fdprocessedid="9h6yog"
                />
              </p>
              <p>
                <input
                  type="email"
                  name="email"
                  id="eml"
                  placeholder="Email Address"
                  fdprocessedid="19r7ap"
                />
              </p>
              <p>
                <input
                  type="text"
                  id="sub"
                  name="subject"
                  placeholder="Subject"
                  fdprocessedid="ls1bpv"
                />
              </p>
              <p>
                <textarea
                  placeholder="Write your message here..."
                  id="msg"
                  defaultValue={""}
                />
              </p>
              <p>
                <button type="submit" fdprocessedid="huytln">
                  Send Message
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
  </div>
  )
}
