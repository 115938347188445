import React from 'react';
import '@fortawesome/fontawesome-free/css/all.css';
import { Link } from "react-router-dom";

export default function Header() {
  return (
    <div> 
      <header className="filmoja-header-area">
          {/* Header Top Area Start */}
          <div className="header-top-area">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-6 col-sm-4"  style={{marginLeft:'35rem'}}>
                          <div className="header-top-social">
                              <ul>
                                  <li>
                                      <a href="https://www.facebook.com/pushkarrangmanch/">
                                          <i className="fab fa-facebook-square" />
                                      </a>
                                  </li>
                                 
                                  <li>
                                      <a href="https://www.instagram.com/pushkarrangmanch/?hl=en">
                                          <i className="fa-brands fa-instagram"/>
                                      </a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          {/* Header Top Area End */}
          {/* Main Header Area Start */}
          <div className="main-header-area">
              <div className="container">
                  <div className="row">
                      <div className="col-lg-2">
                          <div className="site-logo">
                              <a href="/">
                                  <img src="./logo.jpeg" alt="PuskarRangmanch" style={{height:'3.9rem'}} />
                              </a>
                          </div>
                          {/* Responsive Menu Start */}
                          <div className="filmoja-responsive-menu">
                              <div className="slicknav_menu">
                                  <a
                                      href="##"
                                      aria-haspopup="true"
                                      role="button"
                                      tabIndex={0}
                                      className="slicknav_btn slicknav_collapsed"
                                  >
                                      <span className="slicknav_menutxt">MENU</span>
                                      <span className="slicknav_icon">
                                          
                                      </span>
                                  </a>
                                  <ul
                                      className="slicknav_nav slicknav_hidden"
                                      aria-hidden="true"
                                      role="menu"
                                      style={{ display: "none" }}
                                  >
                                      <li>
                                          <Link
                                              to="/"
                                              role="menuitem"
                                              tabIndex={-1}
                                          >
                                              Home
                                          </Link>
                                      </li>
                                      <li>
                                          <Link
                                              to="/about"
                                              role="menuitem"
                                              tabIndex={-1}
                                          >
                                              About Us
                                          </Link>
                                      </li>
                                      <li>
                                          <Link
                                              to="/gallery"
                                              role="menuitem"
                                              tabIndex={-1}
                                          >
                                              Gallery
                                          </Link>
                                      </li>
                                      <li>
                                          <Link
                                              to="/team"
                                              role="menuitem"
                                              tabIndex={-1}
                                          >
                                              Team
                                          </Link>
                                      </li>
                                      <li>
                                          <Link
                                              to="/contact"
                                              role="menuitem"
                                              tabIndex={-1}
                                          >
                                              Contact Us
                                          </Link>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                          {/* Responsive Menu End */}
                      </div>
                      <div className="col-lg-4">
                          <div className="header-main-search hide-mobile">
                          </div>
                      </div>
                      <div className="col-lg-6">
                          <div className="mainmenu">
                              <nav>
                                  <ul id="responsive_navigation">
                                      <li>
                                          <Link to="/">
                                              Home
                                          </Link>
                                      </li>
                                      <li>
                                          <Link to="/about">
                                              About Us
                                          </Link>
                                      </li>
                                      <li>
                                          <Link to="/gallery">
                                              Gallery
                                          </Link>
                                      </li>
                                      <li>
                                          <Link to="/team">
                                              Team
                                          </Link>
                                      </li>
                                      <li>
                                          <Link to="/contact">
                                              Contact
                                          </Link>
                                      </li>
                                  </ul>
                              </nav>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          {/* Main Header Area End */}
      </header>
  </div>
  )
}
